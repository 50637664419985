<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        notification.type == "user_update"
          ? t("notification_types.user_update")
          : notification.type == "deadline"
          ? t("notification_types.deadline")
          : notification.type == "alteration"
          ? t("notification_types.alteration")
          : notification.type == "meter"
          ? t("notification_types.meter")
          : t("notification_types.request")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card mb-4 p-4">
      <div v-if="notification.type == 'user_update'">
        <div
          class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div>
            <label class="block">{{ t("labels.sender") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ sender?.firstName }} {{ sender?.lastName }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.address") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else
              >{{ sender?.address }}, {{ sender?.postalCode }}
              {{ sender?.city }}</span
            >
          </div>
          <div>
            <label class="block">{{ t("labels.phone") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ sender?.phone }}</span>
          </div>
        </div>
        <hr class="my-4 border-slate-300" />
        <div>
          <h3>{{ t("headings.updated_data") }}</h3>
        </div>
        <div
          class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div>
            <label class="block">{{ t("labels.name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else
              >{{ notification?.updatedValues?.firstName }}
              {{ notification?.updatedValues?.lastName }}</span
            >
          </div>
          <div>
            <label class="block">{{ t("labels.address") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else
              >{{ notification?.updatedValues?.address }},
              {{ notification?.updatedValues?.postalCode }}
              {{ notification?.updatedValues?.city }}</span
            >
          </div>
          <div>
            <label class="block">{{ t("labels.phone") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ notification?.updatedValues?.phone }}</span>
          </div>
        </div>
      </div>
      <div
        v-else-if="notification.type === 'deadline'"
        class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
      >
        <div>
          <label class="block">{{ t("labels.topic") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ t("notification_types.deadline") }}</span>
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.date") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else>{{
            getLocaleDate(locale, notification.createdAt)
          }}</span>
        </div>
        <div>
          <label class="block">{{ t("labels.deadline") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else class="text-rose-600">{{
            getLocaleDate(locale, notification.endDate)
          }}</span>
        </div>
        <div>
          <label class="block">{{ t("labels.task") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <button
            v-else
            class="underline"
            @click="router.push(`/tasks/${notification.taskId}`)"
          >
            {{ t("labels.task") }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
      >
        <div>
          <label class="block">{{ t("labels.topic") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ notification.topic }}</span>
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.date") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else>{{
            getLocaleDate(locale, notification.createdAt)
          }}</span>
        </div>
        <div>
          <label class="block">{{ t("labels.sender") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else>{{ sender?.firstName }} {{ sender?.lastName }}</span>
        </div>
        <div v-if="project">
          <label class="block">{{ t("labels.project") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <button
            v-else
            class="underline"
            @click="router.push(`/projects/${project.id}`)"
          >
            {{ project.name }}
          </button>
        </div>
        <div v-else-if="task">
          <label class="block">{{ t("labels.task") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <button
            v-else
            class="underline"
            @click="router.push(`/tasks/${task.id}`)"
          >
            {{ task.name }}
          </button>
        </div>
        <div>
          <label class="block">{{ t("labels.estate_name") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ getEstateName(notification.estateId, estates) }}</span>
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.building") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{
              getBuilding(
                estates,
                notification.estateId,
                notification.buildingId
              )
            }}</span>
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.apartment") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ notification.apartment }}</span>
          </div>
        </div>
        <div
          v-for="alterationType of notification.alterationTypes"
          :key="alterationType.label"
        >
          <label class="block">{{
            t(`alteration_types.${alterationType.label}`)
          }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else-if="alterationType.includes">
            <p>
              {{ t("labels.contractor") }}: {{ alterationType.contractorInfo }}
            </p>
            <p v-if="alterationType.supervisorInfo">
              {{ t("labels.supervisor") }}: {{ alterationType.supervisorInfo }}
            </p>
          </div>
          <p v-else>{{ t("labels.no") }}</p>
        </div>
        <div class="col-span-3">
          <label class="block">{{ t("labels.content") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ notification.content }}</span>
          </div>
        </div>
        <div v-if="notification.image" class="col-span-3">
          <label class="block">{{ t("labels.image") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <a
              class="underline"
              href="javascript:;"
              @click="openFile(notification.image)"
              >{{ t("labels.image") }}</a
            >
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div
        v-if="notification.type == 'user_update'"
        class="flex justify-end gap-2"
      >
        <form-button
          :command="() => acceptData()"
          :disabled="waiting"
          :icon="CheckIcon"
          id="accept-update-button"
          label="accept"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
        <form-button
          :command="() => rejectData()"
          :disabled="waiting"
          :icon="XMarkIcon"
          id="reject-update-button"
          label="reject"
          textVariant="light"
          type="button"
          variant="danger"
        />
      </div>
      <div v-else class="flex justify-end gap-2">
        <form-button
          :command="
            () => router.push(`/tasks/create?requestId=${notification.id}`)
          "
          :disabled="project || task ? true : false"
          :icon="PlusIcon"
          id="task-create-button"
          label="create_task"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
        <form-button
          :command="
            () => router.push(`/projects/create?requestId=${notification.id}`)
          "
          :disabled="project || task ? true : false"
          :icon="PlusIcon"
          id="project-create-button"
          label="create_project"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getBuilding, getEstateName } from "../utils/estateUtil";

export default {
  components: {
    Breadcrumb,
    CheckIcon,
    CustomTable,
    FormButton,
    PlusIcon,
    Skeleton,
    XMarkIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const file = computed(() => store.state.file.file);
    const notification = computed(() => store.state.notification.notification);
    const project = computed(() => store.state.project.project);
    const task = computed(() => store.state.task.task);
    const sender = computed(() => store.state.user.user);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.notification.waiting) return true;
      if (store.state.authentication.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "startDate",
        label: "start_date",
      },
      {
        key: "endDate",
        label: "end_date",
      },
      {
        key: "status",
        label: "status",
      },
    ]);
    const searchFields = ref(["name", "startDate", "endDate", "status"]);
    const sortFields = ref(["name", "startDate", "endDate", "status"]);

    const openFile = (path) => {
      store.dispatch("file/getFile", path);
    };

    onMounted(() => {
      if (!estates.value?.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
      store.dispatch(
        "notification/getNotification",
        route.params.notificationId
      );
    });

    watch(
      () => notification.value,
      (notification) => {
        if (notification) {
          store.dispatch("project/getNotificationProject", notification.id);
          store.dispatch("task/getNotificationTask", notification.id);
          if (notification.user) {
            store.dispatch("user/getUser", notification.user);
          }
        }
      }
    );

    watch(
      () => file.value,
      (file) => {
        if (file) {
          window.open(file, "_blank");
        }
      }
    );

    return {
      acceptData: () =>
        store.dispatch("user/acceptData", {
          notificationId: notification.value.id,
          userId: sender.value.id,
        }),
      CheckIcon,
      company,
      estates,
      fields,
      getBuilding,
      getEstateName,
      getLocaleDate,
      locale,
      notification,
      openFile,
      PlusIcon,
      project,
      rejectData: () =>
        store.dispatch("user/rejectData", {
          notificationId: notification.value.id,
          userId: sender.value.id,
        }),
      router,
      searchFields,
      sender,
      sortFields,
      t,
      task,
      waiting,
      XMarkIcon,
    };
  },
};
</script>
