export const getEstateName = (estateId, estates) => {
  const found = estates.find((estate) => estate.id == estateId);
  return found ? found.name : null;
};

export const getBuilding = (estates, estateId, buildingId) => {
  if (estates?.length) {
    const estate = estates.find((estate) => estate.id == estateId);
    if (estate?.buildings) {
      const building = estate.buildings.find(
        (building) => building.id == buildingId
      );
      return `${building.name}, ${building.address} ${building.postalCode} ${building.city}`;
    }
  }
  return null;
};
